import React, { useRef, useEffect, useState } from 'react';

function App() {
  const localAudioRef = useRef(null);
  const remoteAudioRef = useRef(null);
  const [senderPc, setSenderPc] = useState(null); // PeerConnection для отправки
  const [receiverPc, setReceiverPc] = useState(null); // PeerConnection для получения

  useEffect(() => {
    const startConnection = async () => {
      try {
        const localStream = await navigator.mediaDevices.getUserMedia({ audio: true });
        localAudioRef.current.srcObject = localStream;
  
        const sendingPeerConnection = new RTCPeerConnection();
  
        sendingPeerConnection.oniceconnectionstatechange = () => {
          console.log(`ICE connection state: ${sendingPeerConnection.iceConnectionState}`);
        };
  
        localStream.getTracks().forEach(track => sendingPeerConnection.addTrack(track, localStream));
  
        const offer = await sendingPeerConnection.createOffer();
        await sendingPeerConnection.setLocalDescription(offer);
  
        if (sendingPeerConnection.localDescription) {
          const { sdp, type } = sendingPeerConnection.localDescription;
          if (sdp && type) {
            const response = await fetch('/api/offer', {
              method: 'POST',
              body: JSON.stringify({ sdp, type }),
              headers: { 'Content-Type': 'application/json' },
            });
            const answer = await response.json();
            await sendingPeerConnection.setRemoteDescription(new RTCSessionDescription(answer));
          } else {
            console.error('SDP или тип отсутствует в localDescription');
          }
        } else {
          console.error('localDescription отсутствует');
        }
  
        setSenderPc(sendingPeerConnection);
  
        const receivingPeerConnection = new RTCPeerConnection();
  
        receivingPeerConnection.ontrack = event => {
          console.log("Получен трек:", event.track);
          remoteAudioRef.current.srcObject = event.streams[0];
        };
  
        receivingPeerConnection.oniceconnectionstatechange = () => {
          console.log(`ICE connection state (receiving): ${receivingPeerConnection.iceConnectionState}`);
        };
  
        setReceiverPc(receivingPeerConnection);
  
      } catch (error) {
        console.error('Error accessing media devices:', error);
      }
    };
  
    startConnection();
  }, []);

  return (
    <div>
      {/* Локальный аудио, muted чтобы не слышать себя */}
      <audio ref={localAudioRef} autoPlay muted></audio>
      {/* Аудио других пользователей */}
      <audio ref={remoteAudioRef} autoPlay></audio>
    </div>
  );
}

export default App;